



























import { Component, Vue } from "vue-property-decorator";
import {
  RedCapital,
  SelectedCountry,
  TitleTemplatePublic
} from "@/configuration";
import PaymentGateways from "@/components/common/PaymentGateways.vue";
import API from "@/api";
import { mapState } from "vuex";

/* Estados:
   - 1 -> Inactivo
   - 2 -> Activo
   - 3 -> Rechazado
*/

@Component({
  metaInfo: {
    // @ts-ignore
    title: "Club",
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  data() {
    return {
      RedCapital,
      SelectedCountry,
      member: null
    };
  },
  beforeMount() {
    API.getClub()
      .then(res => {
        console.log(res);
      })
      .catch(error => {
        console.log(error);
      });
  },
  methods: {
    createPayment(paymentId: number) {
      console.log(paymentId);
    }
  },
  components: {
    PaymentGateways
  }
})
export default class PayMembership extends Vue {}
